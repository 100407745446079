import { Button } from 'antd'
import { forwardRef } from 'react'
import { withThemeTokens } from './WithThemeTokens'

const OrangeButton = forwardRef(
    withThemeTokens(Button, {
        colorPrimary: 'darkorange',
    })
)

export default OrangeButton
