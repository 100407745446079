import { FullToken, ThemeProvider, useTheme } from 'antd-style'
import { RefObject } from 'react'
import { ComponentType } from 'react'

export const withThemeTokens = (Component, antdTokens: Partial<FullToken>) => {
    const Wrap = (props: any, ref: RefObject<ComponentType>) => {
        const theme = useTheme()
        return (
            <ThemeProvider
                theme={{
                    token: { ...antdTokens },
                }}
                appearance={theme.appearance}
            >
                <Component {...props} ref={ref} />
            </ThemeProvider>
        )
    }

    Wrap.displayName = `withThemeTokens(${Component?.displayName || Component?.name || 'Unknown'})`

    return Wrap
}
