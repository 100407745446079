import Head from "next/head"

const PageHead = ({title}) => {
    let suffix = ''
    if (process.env.GIT_BRANCH == 'develop') {
        suffix = ' [PREVIEW]'
    }

    return (
        <Head><title>{`${title} — VisCap.ai${suffix}`}</title></Head>
    )
}

export default PageHead
